.projects {
  display: block;
  width: 100%;
  z-index: 9;
  margin: auto;
  overflow: hidden;
  /* background: url("../../assets/project.jpg") no-repeat; */
  background-color: #000;
  /* height: 80vh; */
  /* overflow: auto; */
  box-shadow: inset 0 0 0 1000px rgb(0 0 0 / 20%);
  /* background-size: cover;
  background-position: center;
  background-blend-mode: darken; */
}

.projectRow {
}

.projects .title {
  font-weight: bolder;
  font-size: 60px;
  color: #fff;
  height: 100px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 25px;
  text-shadow: 2px 0 0 rgb(0 0 0);
}

.projectList {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-flow: row wrap;
  align-content: flex-end;
  margin-top: 90px;
  /* background-color: yellow; */
}

.projects .thumbnail {
  width: 395px;
  cursor: pointer;
  background-color: rgb(95 153 255 / 50%);
  /* padding:10px; */
  color: #000;
  font-weight: bold;
  height: 100%;
  margin: 30px;
  height: 400px;
  margin: 20px;
}

.thumbnail:hover {
  transform: scale(1.1);
  z-index: 8;
  /* text-shadow: 0 0 6px rgba(255, 255, 255, 1); */
  -webkit-box-shadow: 0 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0 5px 40px -10px rgba(0, 0, 0, 0.57);
  filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
}

.projects .thumbnail img#pay-as-you-go {
  width: 25%;
}

.overflow-hidden {
  overflow: hidden;
}

.thumbnail img {
  /* margin-top: 5px; */
  /* padding: 10px; */
  /* width: 120px; */
  /* height: 50px; */
  width: 90%;
  height: 80%;
  /* display: inline-block; */
  margin: 15px 0 5px 0;
}

.subText {
  background-color: #0e143c4d;
  height: 56px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: #2468ef;
  font-family: Arial, Helvetica, sans-serif
}
