.projectBackGround{
  margin-top: 90px;
}

.project {
  margin: 0;
  background-color: #000;
}

.project img{
  width: 50%;
  height: 50%;
}

.project img#pay-as-you-go{
  width: 20% !important;
  height: 60vh !important;
  /* margin-top: 5%; */
}

@media only screen and (max-width: 480px) {
  .project img{
    width: 90% !important;
    height: 80% !important;
    margin-top: 5%;
  }

  .project img#pay-as-you-go{
    width: 95% !important;
    height: 60vh !important;
    /* margin-top: 5%; */
  }
}

.project div{
    background-color: rgb(0 0 0 / 50%);
}

.project div .technologies {
    background: none;
}

.project div .technologies .tag{
    background-color: #3880ab;
}

.project div .technologies .label{
    background: none;
}

.subTextClassName{
   margin: 20px;
}
