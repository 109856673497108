.homePage {
  color: #fff;
  height: 80vh;
  font-family: Gtwalsheimprotrial, sans-serif;
  margin-top: 90px;
}

.header {
  color: #fff;
  display: flex;
  height: 70vh;
  margin: auto;
  max-width: 940px;
  padding-top: 0px;
}

.profile {
  border-radius: 240px;
  height: 315px;
}

.tagLine {
  filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
  font-weight: bold;
  text-shadow: 2px 0 0 rgb(0 0 0);
  color: rgb(95 153 255 / 50%);
  z-index: 100;
}

.cv {
  padding: 15px;
  margin: 20px 0;
  background: #0e143c;
  border-radius: 50px;
  display: inline-block;
  width: 150px;
  border: none;
  color: #fff;
  font-weight: bold;
}

.cv:hover {
  text-shadow: 0 0 6px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.4s ease 0s;
}

.speaker {
  height: 50px;
  width: 50px;
  cursor: pointer;
  align-self: center;
  filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
  /* margin-top: 10%; */
}

.speakerMobile {
  height: 28px;
  width: 28px;
  cursor: pointer;
  align-self: center;
  filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
  margin-top: 65%;
}

.minimal {
  border: none;
  display: contents;
}

.minimal:focus {
  outline: none;
  border: none;
}

.icon {
  filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
}

.iconMobile {
  width: 45px;
}

.dot {
  color: #5f99ff;
}

.nameContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.nameContainerMobile {
  position: absolute;
  bottom: 30%;
  margin: 10%;
}

.helloText {
  position: relative;
  z-index: 10;
  display: block;
  color: hsla(0, 0%, 100%, 0.95);
  font-size: 2em;
  font-weight: 700;
}

.nameText {
  position: relative;
  z-index: 100;
  font-size: 4.5em;
  
}

.nameTextMobile {
  position: relative;
  z-index: 100;
  font-size: 3.5em;
}

.imageContainer {
  flex: 1;
}

.image {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  opacity: 1;
  transform-style: preserve-3d;
  height: 100%;
  background-image: url("../../assets/home.jpg");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.description {
  text-align: left;
  margin-left: 35px;
}

.descriptionMobile {
  text-align: center;
  bottom: 0;
  position: absolute;
}

@keyframes animated-text {
  from {
    width: 0;
  }
  to {
    width: 910px;
  }
}

.aboutMe{
  position: relative;
  z-index: 100;
  padding-right: 0px;
  color: #999;
  font-weight: 300;
  text-align: justify;
  font-family: freight-sans-pro;
}
