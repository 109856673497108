.background-image {
  width: 100%;
  color: #fff;
  position: relative;
  background-color: #3d8e98;
  background-image: url("../../assets/beach.jpg");
  background-repeat: no-repeat;
  background-blend-mode: darken;
  background-position-x: right;
  height: 100vh;
  overflow: hidden;
}

.menuList {
  background-color: rgb(14 20 60 / 30%);
  position: absolute;
  width: 85%;
  height: 73%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(2px 3px 5px black);
}

.ulList {
  list-style-type: none;
  padding-left: 15px;
  padding-top: 15px;
  padding-right: 38px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: x-large;
  text-align: left;
  margin: 0;
  filter: drop-shadow(2px 4px 6px black);
}

.link {
  text-decoration: none;
}

.entry {
  color: #fff;
  /* font-size: 30px; */
  /* margin-left: 30px; */
  text-decoration: none;
  /* line-height: 60px; */
}

.background-image .close {
  color: #aaaaaa;
  float: right;
  font-size: 30px;
  font-weight: bold;
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 10px;
  z-index: 8;
}

.background-image .close:hover,
.background-image .close:focus {
  color: #3d8e98;
  text-decoration: none;
  cursor: pointer;
}

.contact {
  text-align: center;
  width: 85%;
  height: 35%;
  left: 48%;
  transform: translate(-50%, 0);
  filter: drop-shadow(2px 3px 5px black);
  display: block;
  position: absolute;
  /* background: url('../../assets/talkToMe.png'); */
  z-index: 5;
}

.icon {
  width: 50px;
}

.stackOverflow {
  margin-bottom: 6px;
}

.menuItemPink {
  /* border-radius: 35px; */
  width: 100%;
  /* background-color: rgb(253 68 239 / 20%); */
  margin-bottom: 25px;
  cursor: pointer;
}

.navbar {
  height: 10vh;
  position: fixed;
  z-index: 1000;
  background-color: black;
  width: 100%;
}

.navbarList {
  list-style-type: none;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  max-height: 80px;
  max-width: 1040px;
}

.navbarList li {
  margin-left: 30px;
  font-weight: 700;
}

.navbarList li:first-child{
  margin-left: 5px;
}

.active{
  text-decoration: underline;
}

.download{
  flex: 9;
  text-align: right;
  color:#fff
}

.downloadImg{
  width: 20px;
  height: 20px;
  padding-right: 5px;
  vertical-align: bottom;
}

.downloadText{
  text-decoration: none;
  color: white;
  display: flex;
  justify-content: flex-end;
}
