.card {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
  background-color: #0e143c;
  color: #fff;
  flex-direction: column;
}

.img {
  width: 90%;
  height: auto;
  display: inline-block;
}

.full-img {
  width: 100%;
  height: 100%;
  display: inline-block;
}

.technologies {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}

.label {
  font-weight: 600;
}

.tag {
  background-color: rgba(245, 244, 252, 0.62);
  padding: 5px;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 2px;
}

.player {
  width: 50% !important;
  height: 50% !important;
  display: inline-block;
}

@media only screen and (max-width: 480px) {
  .player {
    width: 70% !important;
    height: 60vh !important;
    display: inline-block;
    /* margin-top: 5%; */
  }
}

.card .title {
  text-shadow: 2px 0 0 #0e1a6b;
  font-weight: 600;
  margin-bottom: 5px;
}
