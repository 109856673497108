.recommendationContainer {
  .recommendation {
    z-index: 2;
    /* padding: 0 80px 0 80px; */
    background-color: #000;
  }

  .rcRow {
    height: 20px;
  }

  .testimonial {
    background-color: #000;
  }

  .title {
    font-weight: 500;
    font-size: 25px;
    padding: 14px;
    color: #2468ef;
    text-shadow: 2px 0 0 rgb(0 0 0);
    margin-top: 90px;
  }
}
